import React from 'react';
import { DateTime } from 'luxon';
import { ButtonTrigger } from 'shared/components/triggers';

import { IDatePickerTriggerProps } from '../model';

export const DatePickerTrigger = (props: IDatePickerTriggerProps) => {
  const { label, value, variant = 'ghost', className, dateFormat, placeholder, ...otherProps } = props;

  const isSelected = DateTime.isDateTime(value);

  const _getLabel = () => {
    if (isSelected) {
      return label || value.toFormat(dateFormat);
    }

    return placeholder;
  };

  const _getClasses = () => {
    return `${className} ${isSelected ? 'selected' : 'placeholder'}`.trim();
  };

  return <ButtonTrigger {...otherProps} label={_getLabel()} variant={variant} className={_getClasses()} />;
};
