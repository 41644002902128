import { ICreateTransactionOptions } from './interfaces';
import { TransactionManager } from './TransactionManager';

const manager = new TransactionManager();

export const createTransaction = (options?: ICreateTransactionOptions) => {
  return manager.create(options);
};

export const releaseTransaction = (id: string, forced?: boolean) => {
  return manager.release(id, forced);
};

export const removeTransactionFromQueue = (id: string) => {
  return manager.removeFromQueue(id);
};
