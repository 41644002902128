import React from 'react';
import styled from 'styled-components';
import { getBlue300Color } from 'core/theme/helpers';
import { ITriggerProps } from 'shared/components/dropdown';
import { IconButton } from 'shared/components/button';
import { DotsLg } from 'shared/components/icons';

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &.active {
    color: ${getBlue300Color};
  }
`;

export const HorizontalDotsTrigger = (props: ITriggerProps) => {
  const { isOpen, className, getRef, onToggle } = props;

  const classes = isOpen ? `${className} active` : className;

  return (
    <StyledIconButton ref={getRef} variant="ghost-secondary" className={classes} onClick={onToggle}>
      <DotsLg width={26} height={26} />
    </StyledIconButton>
  );
};
