import { ReactElement } from 'react';
import { TippyProps } from '@tippyjs/react';

export enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export type TooltipContainerWidthType = 'content' | 'full';

export interface ITooltipProps extends Omit<TippyProps, 'render' | 'popperOptions'> {
  width?: number;
  content?: string | ReactElement;
  placement?: TooltipPosition;
  className?: string;
  containerWidth?: TooltipContainerWidthType;
}
