import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getBlackColor, getSMBorderRadius, getSMFontSize } from 'core/theme/helpers';

import { ButtonBorderRadius, ButtonIconColorType, ButtonVariantType } from '../model';

interface IButtonVariantStylesProps {
  $variant: ButtonVariantType;
}

interface IButtonBorderRadiusStylesProps {
  $borderRadius: ButtonBorderRadius;
}

interface IIconContainerProps {
  $iconColor: ButtonIconColorType;
}

const buttonGhostVariantStyles = css`
  gap: 4px;
  padding: 0;
  background: none;
  min-height: auto;
  border-radius: 0;
`;

export const commonButtonStyles = css`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  color: ${getBlackColor};
  font-size: ${getSMFontSize};
  min-height: 40px;
  box-sizing: border-box;
  transition: all 300ms ease-out;
  border-radius: ${getSMBorderRadius};

  &:disabled {
    cursor: default;
  }
`;

export const getButtonVariantStyles = (props: ThemedStyledProps<IButtonVariantStylesProps, ITheme>) => {
  const { theme, $variant } = props;

  switch ($variant) {
    case 'ghost':
      return css`
        ${buttonGhostVariantStyles}

        color: ${theme.colors.black};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue300};
        }

        &:disabled {
          color: ${theme.colors.gray700};
        }
      `;
    case 'ghost-secondary':
      return css`
        ${buttonGhostVariantStyles}

        color: ${theme.colors.gray800};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue300};
        }

        &:disabled {
          color: ${theme.colors.gray700};
        }
      `;
    case 'ghost-primary': {
      return css`
        ${buttonGhostVariantStyles}

        color: ${theme.colors.blue300};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue400};
        }

        &:disabled {
          color: ${theme.colors.gray700};
        }
      `;
    }
    case 'ghost-danger':
      return css`
        ${buttonGhostVariantStyles}

        color: ${theme.colors.red400};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.red300};
        }

        &:disabled {
          color: ${theme.colors.red200};
        }
      `;
    case 'basic':
      return css`
        background: ${theme.colors.gray200};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.gray400};
        }

        &:disabled {
          color: ${theme.colors.gray800};
          background: ${theme.colors.gray400};
        }
      `;
    case 'danger':
      return css`
        color: ${theme.colors.white};
        background: ${theme.colors.red400};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.red400};
        }

        &:disabled {
          background: ${theme.colors.red200};
        }
      `;
    case 'bordered':
      return css`
        color: ${theme.colors.black};
        border: 1px solid ${theme.colors.gray500};
        background: ${theme.colors.white};

        &:hover,
        &:focus-visible {
          border-color: ${theme.colors.gray600};
        }

        &:disabled {
          color: ${theme.colors.gray800};
          border-color: ${theme.colors.gray500};
        }

        & .left-icon-container {
          color: ${theme.colors.gray800};
        }
      `;
    case 'highlighted':
      return css`
        &:hover,
        &:focus-visible {
          background: ${theme.colors.gray200};
        }

        &:disabled {
          background: none;
        }

        & .left-icon-container {
          color: ${theme.colors.gray800};
        }
      `;
    case 'filled':
      return css`
        background: ${theme.colors.gray100};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.gray400};
        }

        &:disabled {
          background: ${theme.colors.gray100};
        }

        & .left-icon-container {
          color: ${theme.colors.gray800};
        }
      `;
    case 'filled-secondary':
      return css`
        color: ${theme.colors.white};
        background: ${theme.colors.blue600};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.blue500};
        }

        &:disabled {
          background: ${theme.colors.blue600};
        }
      `;
    case 'filled-selected':
      return css`
        background: ${theme.colors.blue100};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.blue200};
        }

        &:disabled {
          background: ${theme.colors.blue100};
        }

        & .left-icon-container {
          color: ${theme.colors.blue300};
        }
      `;
    case 'filled-tertiary':
      return css`
        color: ${theme.colors.gray800};
        background: ${theme.colors.gray200};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue300};
          background: ${theme.colors.blue100};
        }

        &:disabled {
          color: ${theme.colors.gray800};
          background: ${theme.colors.gray200};
        }
      `;
    case 'filled-quaternary': {
      return css`
        color: ${theme.colors.blue300};
        background: ${theme.colors.blue200};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.white};
          background: ${theme.colors.blue300};
        }

        &:disabled {
          color: ${theme.colors.white};
          background: ${theme.colors.gray600};
        }
      `;
    }
    case 'filled-quinary':
      return css`
        color: ${theme.colors.gray800};
        background: ${theme.colors.gray500};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.black};
          background: ${theme.colors.blue200};

          & .left-icon-container {
            color: ${theme.colors.blue300};
          }
        }

        &:disabled {
          color: ${theme.colors.gray800};
          background: ${theme.colors.gray500};

          & .left-icon-container {
            color: ${theme.colors.gray800};
          }
        }

        & .left-icon-container {
          color: ${theme.colors.gray800};
        }
      `;
    case 'primary':
      return css`
        color: ${theme.colors.white};
        background: ${theme.colors.blue300};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.blue400};
        }

        &:disabled {
          background: ${theme.colors.blue250};
        }
      `;
    case 'primary-dark':
      return css`
        color: ${theme.colors.white};
        background: ${theme.colors.blue600};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.blue500};
        }

        &:disabled {
          color: ${theme.colors.white};
          background: ${theme.colors.blue600};
        }
      `;
    case 'primary-gray':
      return css`
        color: ${theme.colors.gray800};
        background: ${theme.colors.gray500};

        &:hover,
        &:focus-visible {
          color: ${theme.colors.white};
          background: ${theme.colors.blue300};
        }

        &:disabled {
          color: ${theme.colors.gray800};
          background: ${theme.colors.gray500};
        }
      `;
    default:
      return css`
        color: ${theme.colors.gray900};

        &:hover,
        &:focus-visible {
          background: ${theme.colors.gray200};
        }

        &:disabled {
          color: ${theme.colors.gray900};
          background: none;
        }
      `;
  }
};

export const getButtonBorderRadiusStyles = (props: ThemedStyledProps<IButtonBorderRadiusStylesProps, ITheme>) => {
  const { theme, $borderRadius } = props;

  return css`
    border-radius: ${theme.border.radius[$borderRadius]};
  `;
};

export const getIconContainerStyles = (props: ThemedStyledProps<IIconContainerProps, ITheme>) => {
  const { $iconColor } = props;

  if ($iconColor === 'self') {
    return;
  }

  return css`
    color: inherit;

    & svg {
      & *[fill]:not(*[fill='none']) {
        fill: currentColor;
      }

      & *[stroke]:not(*[stroke='none']) {
        stroke: currentColor;
      }
    }
  `;
};

export const IconContainer = styled.i<IIconContainerProps>`
  ${getIconContainerStyles}

  font-style: normal;
  line-height: 0;
`;
