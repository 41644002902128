import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

import { DIALOG_PADDING_VALUE } from '../../model';

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px ${DIALOG_PADDING_VALUE}px;
  box-sizing: border-box;
  border-top: 1px solid ${getGray400Color};
`;

export const FooterLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: auto;
`;
