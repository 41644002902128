import * as React from 'react';
function SvgPauseSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.977 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.65 6.628V11.4H6.6V6.628 6.6h1.05v.028zM11.4 6.628V11.4h-1.05V6.628 6.6h1.05v.028z"
        fill="#8C939F"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgPauseSm;
