import React, { forwardRef } from 'react';

import { ITextInputProps } from './interfaces';
import { Container, StyledInput, IconContainer } from './styled';

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>((props, ref) => {
  const {
    icon,
    error = false,
    variant = 'filled',
    disabled = false,
    className,
    iconPosition = 'left',
    containerRef,
    ...otherProps
  } = props;

  const withIcon = !!icon;

  const _getClasses = () => {
    let classes = `${className} ${variant}`;

    if (withIcon) {
      classes += 'with-icon';
    }

    return classes.trim();
  };

  return (
    <Container ref={containerRef} className={_getClasses()} $error={error} $variant={variant}>
      {icon && (
        <IconContainer className="icon-container" $iconPosition={iconPosition}>
          {icon}
        </IconContainer>
      )}

      <StyledInput
        ref={ref}
        disabled={disabled}
        className="input-field"
        autoComplete="off"
        $error={error}
        $variant={variant}
        $withIcon={withIcon}
        $iconPosition={iconPosition}
        {...otherProps}
      />
    </Container>
  );
});

TextInput.displayName = 'TextInput';
