import { ThemedStyledProps } from 'styled-components';

import { ITheme } from '../theme.interface';

export const getPrimaryShadow = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.shadow.primary;

export const getSecondaryShadow = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.shadow.secondary;

export const getTertiaryShadow = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.shadow.tertiary;

export const getQuaternaryShadow = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.shadow.quaternary;

export const getQuinaryShadow = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.shadow.quinary;
