import React, { forwardRef } from 'react';

import { IIconButtonProps } from '../../model';

import { StyledButton } from './styled';

export const IconButton = forwardRef<HTMLElement, IIconButtonProps>((props, ref) => {
  const {
    role,
    variant = 'ghost-secondary',
    component = 'button',
    disabled = false,
    noPadding = false,
    children,
    className,
    ...otherProps
  } = props;

  const _getRole = () => {
    if (component === 'button') {
      return role;
    }

    return role ?? 'button';
  };

  const _getClasses = () => {
    let classes = className;

    if (disabled) {
      classes = `${className} disabled`.trim();
    }

    return classes;
  };

  return (
    <StyledButton
      ref={ref}
      as={component as any}
      role={_getRole()}
      disabled={disabled}
      className={_getClasses()}
      $variant={variant}
      $noPadding={noPadding}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
});

IconButton.displayName = 'IconButton';
