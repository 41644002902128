/* eslint-disable no-console */
enum COLOR {
  GREEN = '#88baa4',
  RED = '#cb9e92',
  ORANGE = '#ccb294',
}

export class Logger {
  constructor(private readonly subject: string) {}

  static subject(subject: string) {
    return new Logger(subject);
  }

  private getTimestamp() {
    return new Date().toTimeString().split(' ')[0];
  }

  private formatMsg(msg: string) {
    return `%c${this.getTimestamp()} [${this.subject}]: ${msg}`;
  }

  info(msg: string, ...context: unknown[]) {
    console.log(this.formatMsg(msg), `color: ${COLOR.GREEN}`, context);
  }

  error(msg: string, ...context: unknown[]) {
    console.log(this.formatMsg(msg), `color: ${COLOR.RED}`, context);
  }

  warn(msg: string, ...context: unknown[]) {
    console.log(this.formatMsg(msg), `color: ${COLOR.ORANGE}`, context);
  }

  group(msg: string) {
    console.group(this.formatMsg(msg), `color: ${COLOR.GREEN}`);
  }

  groupEnd() {
    console.groupEnd();
  }
}
