import React from 'react';

import { ISwitcherProps } from '../model';
import { SwitcherProvider } from '../providers';

import { SwitcherItem } from './SwitcherItem';
import { Container } from './styled';

export const Switcher = <T extends string | number>(props: ISwitcherProps<T>) => {
  const { name, value, options, children, fullWidth = false, className, onChange } = props;

  return (
    <SwitcherProvider name={name} value={value} onChange={onChange}>
      <Container className={className} $fullWidth={fullWidth}>
        {options ? options?.map((option) => <SwitcherItem key={option.value} {...option} />) : children}
      </Container>
    </SwitcherProvider>
  );
};
