import React, { VFC } from 'react';

import { IDialogFooterProps } from '../../model';

import { FooterContainer, FooterLeftSide } from './styled';

interface IChildrenComponents {
  LeftSide: typeof FooterLeftSide;
}

export const DialogFooter: VFC<IDialogFooterProps> & IChildrenComponents = (props) => {
  const { children, className } = props;

  return <FooterContainer className={className}>{children}</FooterContainer>;
};

DialogFooter.LeftSide = FooterLeftSide;
