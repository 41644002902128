import { isNumber } from 'lodash';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme';

import { IFlexContainerProps } from '../model';

const getFlexStyles = (props: ThemedStyledProps<IFlexContainerProps, ITheme>) => {
  const { $gap = 'normal', $wrap, $align = 'stretch', $direction = 'row', $justify = 'flex-start' } = props;

  return css`
    display: flex;
    gap: ${isNumber($gap) ? `${$gap}px` : $gap};
    flex-wrap: ${$wrap ? 'wrap' : 'nowrap'};
    align-items: ${$align};
    flex-direction: ${$direction};
    justify-content: ${$justify};
  `;
};

export const FlexContainer = styled.div<IFlexContainerProps>`
  ${getFlexStyles};

  box-sizing: border-box;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  ${({ $noOverflow }) =>
    $noOverflow &&
    css`
      overflow: hidden;
    `}
`;
