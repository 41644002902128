import * as React from 'react';
function SvgVerifySm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.285 9l1.808 1.816 3.622-3.63"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.063 1.838c.517-.443 1.365-.443 1.89 0l1.185 1.02c.225.195.645.352.945.352h1.275c.795 0 1.447.653 1.447 1.448v1.275c0 .292.158.72.353.945l1.02 1.185c.442.517.442 1.365 0 1.89l-1.02 1.185a1.662 1.662 0 00-.353.945v1.275c0 .795-.652 1.447-1.447 1.447h-1.275c-.293 0-.72.158-.945.353l-1.185 1.02c-.518.442-1.365.442-1.89 0l-1.185-1.02a1.662 1.662 0 00-.945-.353H4.635a1.453 1.453 0 01-1.447-1.447v-1.283c0-.292-.158-.712-.345-.937L1.83 9.945c-.435-.517-.435-1.357 0-1.875l1.013-1.192c.187-.225.345-.645.345-.938V4.65c0-.795.652-1.447 1.447-1.447h1.298c.292 0 .72-.158.945-.353l1.185-1.012z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgVerifySm;
