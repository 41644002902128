import { useEffect, useMemo } from 'react';
import { getRandomNumber } from 'shared/helpers/number';

import { EventType, HandlerType, EventContext } from '../interfaces';

import { useEventSubscription } from './use-event-subscription';

interface IOptions {
  name?: string;
  skip?: boolean;
  eventType: EventType | EventType[];
  priority?: number;
}

export const useEvent = <T>(options: IOptions, handler: HandlerType<T>, context?: EventContext) => {
  const { name, skip = false, eventType, priority = 1 } = options;

  const subscriptionId = useMemo(() => {
    return `subscription-${getRandomNumber(1, 1000000)}`;
  }, []);

  const { subscribe, unsubscribe } = useEventSubscription();

  useEffect(() => {
    if (skip) {
      return;
    }

    subscribe<T>(subscriptionId, {
      name,
      context,
      priority,
      eventType,
      handler,
    });

    return () => {
      unsubscribe(subscriptionId);
    };
  }, [name, skip, context, subscriptionId, handler, subscribe, unsubscribe]);
};
