import { Logger } from 'core/logger/logger';

import { observable } from '../observable';
import { IEvent, IEventMetadata } from '../interfaces';

export const emitEvent = <T>(value: T, metadata: IEventMetadata) => {
  const event: IEvent<T> = {
    value,
    __metadata: metadata,
  };

  const subject = metadata.subscription ? 'SERVER_EVENT' : 'LOCAL_EVENT';

  Logger.subject(subject).info('Received event:', metadata.type, event);

  observable.next(event);
};
