/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppIntegrationConnectionSyncStatus {
  Cancelled = "Cancelled",
  Deleted = "Deleted",
  Failed = "Failed",
  Paused = "Paused",
  Pending = "Pending",
  Syncing = "Syncing",
}

export enum AppIntegrationKind {
  GoogleCalendar = "GoogleCalendar",
  GoogleMeet = "GoogleMeet",
  ZoomMeet = "ZoomMeet",
}

export enum AppNotificationFilter {
  Following = "Following",
  Responsible = "Responsible",
}

export enum AppNotificationNodeKind {
  ChatMessageScheduledEvent = "ChatMessageScheduledEvent",
  ChatMessageTask = "ChatMessageTask",
}

export enum AttachmentKind {
  Avatar = "Avatar",
  FileAttachment = "FileAttachment",
  Support = "Support",
  TextAttachment = "TextAttachment",
}

export enum AttachmentReferenceType {
  ChatMessage = "ChatMessage",
  Project = "Project",
  ScheduledEvent = "ScheduledEvent",
  Task = "Task",
  Team = "Team",
  User = "User",
  Workspace = "Workspace",
}

export enum BillingSubscriptionCancellationFeedback {
  CustomerService = "CustomerService",
  LowQuality = "LowQuality",
  MissingFeatures = "MissingFeatures",
  Other = "Other",
  SwitchedService = "SwitchedService",
  TooComplex = "TooComplex",
  TooExpensive = "TooExpensive",
  Unused = "Unused",
}

export enum BoardViewState {
  Multi = "Multi",
  Private = "Private",
}

export enum ChatMessageKind {
  MediaMessage = "MediaMessage",
  SystemMessage = "SystemMessage",
  UserMessage = "UserMessage",
}

export enum ChatMessageReferenceKind {
  Project = "Project",
  ScheduledEvent = "ScheduledEvent",
  Task = "Task",
  Team = "Team",
  User = "User",
  Workspace = "Workspace",
}

export enum DevicePlatform {
  ANDROID = "ANDROID",
  BROWSER = "BROWSER",
  IOS = "IOS",
}

export enum DomainReferenceType {
  PROJECT = "PROJECT",
  TEAM = "TEAM",
  WORKSPACE = "WORKSPACE",
}

export enum EntitySourceType {
  Integration = "Integration",
  Self = "Self",
  Tutorial = "Tutorial",
}

export enum FollowerKind {
  All = "All",
  None = "None",
  UserComments = "UserComments",
}

export enum GoogleMeetAccessKind {
  Open = "Open",
  Restricted = "Restricted",
  Trusted = "Trusted",
}

export enum MentionReferenceType {
  ScheduledEvent = "ScheduledEvent",
  Task = "Task",
}

export enum Oauth2Client {
  Android = "Android",
  Ios = "Ios",
  Web = "Web",
  WebGsi = "WebGsi",
}

export enum Oauth2LoginStatus {
  SignIn = "SignIn",
  SignUp = "SignUp",
}

export enum Oauth2Provider {
  Facebook = "Facebook",
  Google = "Google",
  Ios = "Ios",
  Zoom = "Zoom",
}

export enum QueryTasksAttrSortBy {
  CommonRank = "CommonRank",
  CreatedAt = "CreatedAt",
  StatusRank = "StatusRank",
}

export enum RecurrenceFrequencyType {
  BusinessDay = "BusinessDay",
  Custom = "Custom",
  Day = "Day",
  Month = "Month",
  Week = "Week",
  Year = "Year",
}

export enum RecurrenceScope {
  AllFollowing = "AllFollowing",
  OnlyThis = "OnlyThis",
}

export enum RecurrenceType {
  RecurrenceException = "RecurrenceException",
  RecurrenceInstance = "RecurrenceInstance",
  RecurrenceTemplate = "RecurrenceTemplate",
  Single = "Single",
}

export enum RegistrationFlowStep {
  Avatar = "Avatar",
  Completed = "Completed",
  CustomerKind = "CustomerKind",
  EmailVerification = "EmailVerification",
  OrgCreation = "OrgCreation",
  Preferences = "Preferences",
  SignUp = "SignUp",
}

export enum ScheduledEventLocationKind {
  GoogleMeet = "GoogleMeet",
  PlainLocation = "PlainLocation",
  ZoomMeet = "ZoomMeet",
}

export enum SubscriptionPlanPriceIntervalKind {
  Month = "Month",
  Year = "Year",
}

export enum SubtaskStatus {
  Active = "Active",
  Completed = "Completed",
}

export enum TaskStatus {
  Completed = "Completed",
  InProgress = "InProgress",
  New = "New",
  Scheduled = "Scheduled",
}

export enum TaskStatusKind {
  Completed = "Completed",
  Custom = "Custom",
  InProgress = "InProgress",
  New = "New",
  Scheduled = "Scheduled",
}

export enum TaskTimerStatus {
  Finalized = "Finalized",
  Paused = "Paused",
  Running = "Running",
}

export enum TimeblockStatus {
  Active = "Active",
  Completed = "Completed",
}

export enum UserExternalLinkedAccountState {
  Active = "Active",
  InvalidGrant = "InvalidGrant",
}

export enum UserInviteKind {
  Project = "Project",
  ScheduledEvent = "ScheduledEvent",
  Team = "Team",
}

export enum UserInviteStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Invited = "Invited",
  PreAccepted = "PreAccepted",
}

export enum UserParticipantStatus {
  Accepted = "Accepted",
  Active = "Active",
  Attended = "Attended",
  Declined = "Declined",
  Deleted = "Deleted",
  Invited = "Invited",
}

export enum UserParticipantSystemRole {
  Admin = "Admin",
  Guest = "Guest",
  Member = "Member",
  Organizer = "Organizer",
  Owner = "Owner",
  TeamLeader = "TeamLeader",
}

export enum WorkspaceActivity {
  AgricultureFarming = "AgricultureFarming",
  AutoVehicles = "AutoVehicles",
  BankingFinancialServices = "BankingFinancialServices",
  Beauty = "Beauty",
  BusinessServices = "BusinessServices",
  ConstructionArchitecture = "ConstructionArchitecture",
  ConsultingLegal = "ConsultingLegal",
  EcommerceRetail = "EcommerceRetail",
  Education = "Education",
  Government = "Government",
  ITDevelopment = "ITDevelopment",
  Manufacturing = "Manufacturing",
  MarketingAdvertising = "MarketingAdvertising",
  MedicineHealthcare = "MedicineHealthcare",
  MusicEntertainment = "MusicEntertainment",
  Nonprofit = "Nonprofit",
  Other = "Other",
  PetsAnimals = "PetsAnimals",
  PhotoVideoProduction = "PhotoVideoProduction",
  RealEstate = "RealEstate",
  ScienceTechnology = "ScienceTechnology",
  Sports = "Sports",
  Telecommunication = "Telecommunication",
  TransportLogistics = "TransportLogistics",
  TravelEvents = "TravelEvents",
}

export enum WorkspaceCreatorRole {
  BusinessOwner = "BusinessOwner",
  ExecutiveOfficer = "ExecutiveOfficer",
  Other = "Other",
  ProjectManager = "ProjectManager",
  Specialist = "Specialist",
  TeamLead = "TeamLead",
  Unknown = "Unknown",
}

export enum WorkspaceDefaultTool {
  Calendar = "Calendar",
  Kanban = "Kanban",
  Notes = "Notes",
  People = "People",
  Settings = "Settings",
  TaskList = "TaskList",
}

export enum WorkspaceFolderChildKind {
  Folder = "Folder",
  Project = "Project",
  Team = "Team",
}

export enum WorkspaceFoldersSchemaKind {
  Project = "Project",
  Team = "Team",
}

export enum WorkspaceInitialTeamKind {
  BusinessOps = "BusinessOps",
  CustomerSupport = "CustomerSupport",
  Design = "Design",
  Finance = "Finance",
  HR = "HR",
  IT = "IT",
  Legal = "Legal",
  Marketing = "Marketing",
  Other = "Other",
  Sales = "Sales",
  Unknown = "Unknown",
}

/**
 * Month - every month | Year - every year
 */
export enum WorkspaceSubscriptionItemInterval {
  Month = "Month",
  Year = "Year",
}

export enum WorkspaceSubscriptionState {
  Active = "Active",
  Canceled = "Canceled",
  Expired = "Expired",
  Failed = "Failed",
  Free = "Free",
  Trial = "Trial",
}

export enum WorkspaceSubscriptionStripeStatus {
  Active = "Active",
  Canceled = "Canceled",
  Incomplete = "Incomplete",
  IncompleteExpired = "IncompleteExpired",
  PastDue = "PastDue",
  Paused = "Paused",
  Trialing = "Trialing",
  Unpaid = "Unpaid",
}

export interface AcceptUserInviteInput {
  readonly id: string;
}

export interface AddProjectParticipantsInput {
  readonly participants: ReadonlyArray<ProjectParticipantInput>;
  readonly project: string;
}

export interface AddScheduledEventParticipantsInput {
  readonly eventId: string;
  readonly participants: ReadonlyArray<ScheduledEventParticipantInput>;
  readonly recurrenceScope?: RecurrenceScope | null;
}

export interface AddSpaceToFavoritesInput {
  readonly id: string;
}

export interface AddTeamParticipantsInput {
  readonly participants: ReadonlyArray<TeamParticipantInput>;
  readonly team: string;
}

export interface AuthInput {
  readonly email: string;
  readonly password: string;
}

export interface BoardEventFilterInput {
  readonly fromDate: any;
  readonly toDate: any;
}

export interface BoardInput {
  readonly eventFilter: BoardEventFilterInput;
  readonly participants?: ReadonlyArray<string> | null;
  readonly refId: string;
  readonly refType: DomainReferenceType;
  readonly taskFilter: BoardTaskFilterInput;
  readonly withWaitingList?: boolean | null;
}

export interface BoardTaskFilterInput {
  readonly fromDate: any;
  readonly toDate: any;
}

export interface BulkAddTeamParticipantsInput {
  readonly ops: ReadonlyArray<AddTeamParticipantsInput>;
}

export interface CancelSubscriptionInput {
  readonly comment: string;
  readonly feedback: BillingSubscriptionCancellationFeedback;
  readonly subscriptionId: string;
}

export interface ChangeOwnerProjectInput {
  readonly owner: string;
  readonly project: string;
}

export interface ChangePasswordInput {
  readonly newPassword: string;
  readonly oldPassword: string;
}

export interface ChangeProjectParticipantRoleInput {
  readonly participantId?: string | null;
  readonly projectId: string;
  readonly role: UserParticipantSystemRole;
  readonly userId?: string | null;
}

export interface ChangeTeamParticipantRoleInput {
  readonly role: UserParticipantSystemRole;
  readonly teamId: string;
  readonly userId: string;
}

export interface ChangeWorkspaceOwnerInput {
  readonly owner: string;
  readonly workspace: string;
}

export interface ChangeWorkspaceParticipantRoleInput {
  readonly role: UserParticipantSystemRole;
  readonly user?: string | null;
  readonly workspace: string;
}

export interface ColorTypeInput {
  readonly color: string;
  readonly title: string;
  readonly useEvent: boolean;
  readonly useTask: boolean;
}

export interface CompleteMultipartUploadInput {
  readonly fileId: string;
  readonly fileKey: string;
  readonly id: string;
  readonly parts: ReadonlyArray<CompleteMultipartUploadPartInput>;
}

export interface CompleteMultipartUploadPartInput {
  readonly eTag: string;
  readonly partNumber: number;
}

export interface CreateAppIntegrationGoogleCalendarParametersInput {
  readonly calendarId: string;
  readonly exportEnabled: boolean;
  readonly exportWorkspaces: ReadonlyArray<string>;
  readonly externalLinkedAccountId: string;
  readonly importEnabled: boolean;
  readonly refId?: string | null;
  readonly refType?: DomainReferenceType | null;
  readonly scheduledEventDefaultColor?: string | null;
}

export interface CreateAppIntegrationGoogleMeetParametersInput {
  readonly defaultAccessKind: GoogleMeetAccessKind;
  readonly externalLinkedAccountId: string;
}

export interface CreateAppIntegrationInput {
  readonly googleCalendarParameters?: CreateAppIntegrationGoogleCalendarParametersInput | null;
  readonly googleMeetParameters?: CreateAppIntegrationGoogleMeetParametersInput | null;
  readonly id: string;
  readonly kind: AppIntegrationKind;
  readonly title: string;
  readonly zoomMeetParameters?: CreateAppIntegrationZoomMeetParametersInput | null;
}

export interface CreateAppIntegrationZoomMeetParametersInput {
  readonly externalLinkedAccountId: string;
  readonly userId: string;
}

export interface CreateAttachmentUploadUrl {
  readonly attachmentKind: AttachmentKind;
  readonly contentType?: string | null;
  readonly filename?: string | null;
  readonly id?: string | null;
  readonly refId?: string | null;
  readonly refType?: AttachmentReferenceType | null;
}

export interface CreateBillingSubscriptionCheckoutSessionInput {
  readonly quantity: number;
  readonly subscriptionPlanId: string;
  readonly workspace: string;
}

export interface CreateBillingSubscriptionPortalSessionInput {
  readonly workspace: string;
}

export interface CreateChatMessageInput {
  readonly attachments?: ReadonlyArray<string> | null;
  readonly id: string;
  readonly message: string;
  readonly refId: string;
  readonly refType: ChatMessageReferenceKind;
  readonly reply?: string | null;
}

export interface CreateMultipartUploadInput {
  readonly attachmentKind: AttachmentKind;
  readonly contentType?: string | null;
  readonly filename?: string | null;
  readonly id: string;
  readonly refId?: string | null;
  readonly refType?: AttachmentReferenceType | null;
}

export interface CreateMultipartUploadUrlsInput {
  readonly fileId: string;
  readonly fileKey: string;
  readonly parts: number;
}

export interface CreateNoteInput {
  readonly content: string;
  readonly id?: string | null;
  readonly refId: string;
  readonly refType: DomainReferenceType;
  readonly title: string;
}

export interface CreateProjectInput {
  readonly dueDate?: any | null;
  readonly folder?: string | null;
  readonly guests?: ReadonlyArray<string> | null;
  readonly id: string;
  readonly name: string;
  readonly participants?: ReadonlyArray<ProjectParticipantInput> | null;
  readonly settings?: ProjectSettingsInput | null;
  readonly workspace: string;
}

export interface CreateScheduledEventInput {
  readonly attachments?: ReadonlyArray<string> | null;
  readonly color: string;
  readonly description?: string | null;
  readonly endAt: any;
  readonly id: string;
  readonly location?: string | null;
  readonly locationKind?: ScheduledEventLocationKind | null;
  readonly participants: ReadonlyArray<ScheduledEventParticipantInput>;
  readonly rank?: RankInput | null;
  readonly recurrence?: CreateScheduledEventRecurrenceRuleInput | null;
  readonly refId: string;
  readonly refType: DomainReferenceType;
  readonly remindAt?: any | null;
  readonly reminders?: ReadonlyArray<any> | null;
  readonly source?: string | null;
  readonly startAt: any;
  readonly title: string;
}

export interface CreateScheduledEventRecurrenceRuleInput {
  readonly frequency?: RecurrenceFrequencyType | null;
  readonly rrule?: string | null;
  readonly until?: any | null;
}

export interface CreateSubtaskInput {
  readonly assignee?: string | null;
  readonly id: string;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly status?: SubtaskStatus | null;
  readonly title: string;
}

export interface CreateTagInput {
  readonly id: string;
  readonly name: string;
  readonly workspace: string;
}

export interface CreateTaskInput {
  readonly assignee?: string | null;
  readonly attachments?: ReadonlyArray<string> | null;
  readonly color: string;
  readonly commonRank?: number | null;
  readonly description?: string | null;
  readonly endAt?: any | null;
  readonly id: string;
  readonly recurrenceRule?: CreateTaskRecurrenceRuleInput | null;
  readonly refId: string;
  readonly refType: DomainReferenceType;
  readonly repeatableRule?: TaskRepeatableRuleInput | null;
  readonly reviewer?: string | null;
  readonly source?: string | null;
  readonly startAt?: any | null;
  readonly statusRank?: number | null;
  readonly statusV2?: string | null;
  readonly subtasks?: ReadonlyArray<CreateSubtaskInput> | null;
  readonly tags: ReadonlyArray<string>;
  readonly timeblocks: ReadonlyArray<UpdateTimeblockInput>;
  readonly title: string;
}

export interface CreateTaskRecurrenceRuleInput {
  readonly dueDateShift?: number | null;
  readonly frequency?: RecurrenceFrequencyType | null;
  readonly initialTaskStatus?: string | null;
  readonly rrule?: string | null;
  readonly until?: any | null;
}

export interface CreateTaskStatusInput {
  readonly color: string;
  readonly icon?: string | null;
  readonly id: string;
  readonly name: string;
  readonly refId: string;
  readonly refType: DomainReferenceType;
}

export interface CreateTeamInput {
  readonly id: string;
  readonly name: string;
  readonly participants: ReadonlyArray<TeamParticipantInput>;
  readonly settings?: TeamSettingsInput | null;
  readonly workspace: string;
}

export interface CreateTimeblockInput {
  readonly assignee?: string | null;
  readonly billable?: boolean | null;
  readonly duration?: number | null;
  readonly id: string;
  readonly rank?: RankInput | null;
  readonly source?: string | null;
  readonly startAt?: any | null;
}

export interface CreateUserInput {
  readonly backupEmail?: string | null;
  readonly birthDate?: any | null;
  readonly country?: string | null;
  readonly email: string;
  readonly firstName?: string | null;
  readonly fullName?: string | null;
  readonly inviteToken?: string | null;
  readonly lastName?: string | null;
  readonly location?: string | null;
  readonly password: string;
  readonly phone?: string | null;
  readonly settings?: UserSettingsInput | null;
  readonly track?: UserTrackDataInput | null;
}

export interface CreateWorkspaceFolderInput {
  readonly id: string;
  readonly kind: WorkspaceFoldersSchemaKind;
  readonly name: string;
  readonly rank: number;
  readonly workspaceId: string;
}

export interface CreateWorkspaceInput {
  readonly activity: WorkspaceActivity;
  readonly creatorPhone?: string | null;
  readonly creatorRole?: WorkspaceCreatorRole | null;
  readonly defaultTool?: WorkspaceDefaultTool | null;
  readonly id: string;
  readonly initialTeamKind?: WorkspaceInitialTeamKind | null;
  readonly name: string;
  readonly project?: CreateProjectInput | null;
  readonly team?: CreateTeamInput | null;
}

export interface DeleteAppIntegrationInput {
  readonly id: string;
}

export interface DeleteAttachmentInput {
  readonly id: string;
}

export interface DeleteChatMessageInput {
  readonly id: string;
}

export interface DeleteNoteInput {
  readonly id: string;
  readonly soft: boolean;
}

export interface DeleteProjectInput {
  readonly id: string;
}

export interface DeleteScheduledEventInput {
  readonly id: string;
  readonly recurrenceScope?: RecurrenceScope | null;
}

export interface DeleteSubtaskInput {
  readonly id: string;
  readonly recurrenceScope?: RecurrenceScope | null;
}

export interface DeleteTagInput {
  readonly id: string;
}

export interface DeleteTaskInput {
  readonly id: string;
  readonly recurrenceScope?: RecurrenceScope | null;
}

export interface DeleteTaskStatusInput {
  readonly id: string;
  readonly refId: string;
  readonly refType: DomainReferenceType;
}

export interface DeleteTaskTimerInput {
  readonly id: string;
}

export interface DeleteTeamInput {
  readonly id: string;
}

export interface DeleteTimeblockInput {
  readonly id: string;
  readonly recurrenceScope?: RecurrenceScope | null;
}

export interface DeleteWorkspaceInput {
  readonly id: string;
}

export interface DuplicateProjectInput {
  readonly id: string;
}

export interface DuplicateTaskInput {
  readonly id: string;
  readonly source: UpdateTaskInput;
}

export interface FinalizeTaskTimerInput {
  readonly linkedTimeblock: UpdateTimeblockInput;
  readonly newTimeblock?: UpdateTimeblockInput | null;
  readonly taskId: string;
}

export interface GetAppNotificationsInput {
  readonly filter?: AppNotificationFilter | null;
  readonly limit: number;
  readonly timestamp?: any | null;
}

export interface GetBillingSubscriptionPlanInput {
  readonly countryCode?: string | null;
  readonly workspace: string;
}

export interface GetChatMessagesInput {
  readonly limit: number;
  readonly refId: string;
  readonly refType: ChatMessageReferenceKind;
  readonly timestamp?: any | null;
}

export interface GetMentionUserListInput {
  readonly refId: string;
  readonly refType: MentionReferenceType;
}

export interface GetSubscriptionPaymentLinkInput {
  readonly subscriptionId: string;
}

export interface GetTasksInput {
  readonly assignee?: string | null;
  readonly color?: string | null;
  readonly limit: number;
  readonly refId: string;
  readonly refType: DomainReferenceType;
  readonly sortBy: QueryTasksAttrSortBy;
  readonly status?: string | null;
  readonly timestamp?: any | null;
}

export interface KanbanInput {
  readonly refId: string;
  readonly refType: DomainReferenceType;
}

export interface LeaveProjectInput {
  readonly projectId: string;
  readonly timeblockAssignee?: string | null;
}

export interface LeaveTeamInput {
  readonly team: string;
}

export interface LinkTagInput {
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly tags: ReadonlyArray<string>;
  readonly task: string;
}

export interface MarkReadAppNotificationInput {
  readonly refId: string;
  readonly refType: ChatMessageReferenceKind;
}

export interface MarkReadFeatureUpdateInput {
  readonly id: string;
}

export interface MoveTeamParticipantInput {
  readonly destinationTeamId: string;
  readonly sourceTeamId: string;
  readonly timeblockAssignee?: string | null;
  readonly userId?: string | null;
}

export interface MoveWorkspaceFolderChildInput {
  readonly destinationParentId?: string | null;
  readonly id: string;
  readonly kind: WorkspaceFoldersSchemaKind;
  readonly rank: number;
  readonly sourceParentId?: string | null;
}

export interface Oauth2LoginInput {
  readonly client: Oauth2Client;
  readonly provider: Oauth2Provider;
  readonly settings?: UserSettingsInput | null;
  readonly token: string;
  readonly track?: UserTrackDataInput | null;
}

export interface ProjectParticipantInput {
  readonly email?: string | null;
  readonly id: string;
  readonly user?: string | null;
}

export interface ProjectSettingsInput {
  readonly colorTypes?: ReadonlyArray<ColorTypeInput> | null;
  readonly defaultScheduledEventColor?: string | null;
  readonly defaultTaskColor?: string | null;
  readonly inheritColorTypes?: boolean | null;
  readonly inheritShowTaskTimerFinalizationModal?: boolean | null;
  readonly showTaskTimerFinalizationModal?: boolean | null;
}

export interface RankInput {
  readonly rank: number;
  readonly resource: DomainReferenceType;
}

export interface RegisterDeviceTokenInput {
  readonly id?: string | null;
  readonly platform: DevicePlatform;
  readonly token: string;
}

export interface RemoveProjectParticipantInput {
  readonly participantId?: string | null;
  readonly projectId: string;
  readonly timeblockAssignee?: string | null;
  readonly userId?: string | null;
}

export interface RemoveScheduledEventParticipantInput {
  readonly eventId: string;
  readonly participantId: string;
  readonly recurrenceScope?: RecurrenceScope | null;
}

export interface RemoveSpaceFromFavoritesInput {
  readonly id: string;
}

export interface RemoveTeamParticipantInput {
  readonly teamId: string;
  readonly timeblockAssignee?: string | null;
  readonly userId: string;
}

export interface RemoveWorkspaceFolderInput {
  readonly id: string;
}

export interface RequestPasswordResetInput {
  readonly email: string;
}

export interface ResetPasswordInput {
  readonly email: string;
  readonly password: string;
  readonly token: string;
}

export interface RestoreNoteInput {
  readonly id: string;
}

export interface RevokeDeviceTokenInput {
  readonly id: string;
}

export interface RevokeExternalLinkedAccountInput {
  readonly oauthLink: string;
}

export interface ScheduledEventParticipantInput {
  readonly email?: string | null;
  readonly id: string;
  readonly rank?: RankInput | null;
  readonly role?: UserParticipantSystemRole | null;
  readonly status?: UserParticipantStatus | null;
  readonly user?: string | null;
}

export interface SetExternalLinkedAccountInput {
  readonly client: Oauth2Client;
  readonly provider: Oauth2Provider;
  readonly token: string;
}

export interface SetLastReferenceInput {
  readonly refId: string;
  readonly refType: DomainReferenceType;
  readonly tool: WorkspaceDefaultTool;
}

export interface SetRegistrationFlowStepInput {
  readonly case?: number | null;
  readonly step: RegistrationFlowStep;
}

export interface SetScheduledEventFollowerInput {
  readonly id?: string | null;
  readonly kind: FollowerKind;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly scheduledEventId: string;
  readonly user: string;
}

export interface SetTaskFollowerInput {
  readonly id?: string | null;
  readonly kind: FollowerKind;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly taskId: string;
  readonly user: string;
}

export interface StartTaskTimerInput {
  readonly id?: string | null;
  readonly opTimestamp: number;
  readonly taskId?: string | null;
  readonly timeblockId?: string | null;
}

export interface StopTaskTimerInput {
  readonly id: string;
  readonly opTimestamp: number;
}

export interface SupportInput {
  readonly message: string;
  readonly topic: string;
}

export interface TableViewInput {
  readonly limit?: number | null;
  readonly refId: string;
  readonly refType: DomainReferenceType;
}

export interface TableViewSchemaInput {
  readonly refId: string;
  readonly refType: DomainReferenceType;
}

export interface TaskRepeatableRuleInput {
  readonly assignee?: string | null;
  readonly color?: string | null;
  readonly description?: string | null;
  readonly dueDateShift?: number | null;
  readonly initialTaskStatus?: string | null;
  readonly initialTimeblockDuration?: number | null;
  readonly startAtDateShift?: number | null;
  readonly title?: string | null;
  readonly until?: any | null;
}

export interface TeamParticipantInput {
  readonly email?: string | null;
  readonly id: string;
  readonly role: UserParticipantSystemRole;
  readonly user?: string | null;
}

export interface TeamSettingsInput {
  readonly colorTypes?: ReadonlyArray<ColorTypeInput> | null;
  readonly defaultScheduledEventColor?: string | null;
  readonly defaultTaskColor?: string | null;
  readonly inheritColorTypes?: boolean | null;
  readonly inheritShowTaskTimerFinalizationModal?: boolean | null;
  readonly showTaskTimerFinalizationModal?: boolean | null;
}

export interface TransformScheduledEventInput {
  readonly id: string;
  readonly recurrence: CreateScheduledEventRecurrenceRuleInput;
}

export interface TransformTaskInput {
  readonly id: string;
  readonly recurrence: CreateTaskRecurrenceRuleInput;
}

export interface UnLinkTagInput {
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly tags: ReadonlyArray<string>;
  readonly task: string;
}

export interface UpdateAppIntegrationGoogleCalendarParametersInput {
  readonly exportEnabled: boolean;
  readonly exportWorkspaces?: ReadonlyArray<string> | null;
  readonly importEnabled: boolean;
  readonly refId?: string | null;
  readonly refType?: DomainReferenceType | null;
  readonly scheduledEventDefaultColor?: string | null;
}

export interface UpdateAppIntegrationGoogleMeetParametersInput {
  readonly defaultAccessKind?: GoogleMeetAccessKind | null;
}

export interface UpdateAppIntegrationInput {
  readonly googleCalendarParameters?: UpdateAppIntegrationGoogleCalendarParametersInput | null;
  readonly googleMeetParameters?: UpdateAppIntegrationGoogleMeetParametersInput | null;
  readonly id: string;
  readonly title?: string | null;
}

export interface UpdateBillingSubscriptionSlotsInput {
  readonly quantity: number;
  readonly workspace: string;
}

export interface UpdateChatMessageInput {
  readonly deletedAttachments?: ReadonlyArray<string> | null;
  readonly id: string;
  readonly message: string;
  readonly reply?: string | null;
}

export interface UpdateNoteInput {
  readonly content?: string | null;
  readonly id: string;
  readonly refId?: string | null;
  readonly refType?: DomainReferenceType | null;
  readonly title?: string | null;
}

export interface UpdateProjectInput {
  readonly dueDate?: any | null;
  readonly id: string;
  readonly name: string;
  readonly settings?: ProjectSettingsInput | null;
}

export interface UpdateScheduledEventInput {
  readonly appConnection?: string | null;
  readonly appConnectionExternalId?: string | null;
  readonly color?: string | null;
  readonly description?: string | null;
  readonly endAt?: any | null;
  readonly id: string;
  readonly location?: string | null;
  readonly organizer?: string | null;
  readonly participant?: UpdateScheduledEventParticipantInput | null;
  readonly rank?: RankInput | null;
  readonly recurrenceRule?: CreateScheduledEventRecurrenceRuleInput | null;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly refId?: string | null;
  readonly refType?: DomainReferenceType | null;
  readonly remindAt?: any | null;
  readonly startAt?: any | null;
  readonly title?: string | null;
}

export interface UpdateScheduledEventParticipantInput {
  readonly eventId: string;
  readonly participantId?: string | null;
  readonly rank?: RankInput | null;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly status?: UserParticipantStatus | null;
  readonly userId?: string | null;
}

export interface UpdateSubtaskInput {
  readonly assignee?: string | null;
  readonly id: string;
  readonly order?: number | null;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly status?: SubtaskStatus | null;
  readonly title?: string | null;
}

export interface UpdateTagInput {
  readonly id: string;
  readonly name: string;
}

export interface UpdateTaskInput {
  readonly assignee?: string | null;
  readonly color?: string | null;
  readonly commonRank?: number | null;
  readonly deletedTimeblocks?: ReadonlyArray<string> | null;
  readonly description?: string | null;
  readonly endAt?: any | null;
  readonly estimatedTime?: number | null;
  readonly id: string;
  readonly recurrenceRule?: CreateTaskRecurrenceRuleInput | null;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly refId?: string | null;
  readonly refType?: DomainReferenceType | null;
  readonly repeatableRule?: TaskRepeatableRuleInput | null;
  readonly reviewer?: string | null;
  readonly startAt?: any | null;
  readonly status?: TaskStatus | null;
  readonly statusRank?: number | null;
  readonly statusV2?: string | null;
  readonly timeblocks?: ReadonlyArray<UpdateTimeblockInput> | null;
  readonly title?: string | null;
  readonly type?: RecurrenceType | null;
}

export interface UpdateTaskStatusInput {
  readonly color?: string | null;
  readonly icon?: string | null;
  readonly id: string;
  readonly name?: string | null;
  readonly order?: number | null;
  readonly refId: string;
  readonly refType: DomainReferenceType;
}

export interface UpdateTeamInput {
  readonly id: string;
  readonly name?: string | null;
  readonly settings?: TeamSettingsInput | null;
}

export interface UpdateTimeblockInput {
  readonly assignee?: string | null;
  readonly billable?: boolean | null;
  readonly duration?: number | null;
  readonly id: string;
  readonly manualSpentTime?: number | null;
  readonly rank?: RankInput | null;
  readonly recurrenceScope?: RecurrenceScope | null;
  readonly startAt?: any | null;
  readonly status?: TimeblockStatus | null;
}

export interface UpdateUserInput {
  readonly avatar?: string | null;
  readonly avatarPreviews?: ReadonlyArray<UserAvatarPreviewInput> | null;
  readonly birthDate?: any | null;
  readonly country?: string | null;
  readonly firstName?: string | null;
  readonly fullName?: string | null;
  readonly lastName?: string | null;
  readonly location?: string | null;
  readonly phone?: string | null;
  readonly settings?: UserSettingsInput | null;
  readonly skype?: string | null;
}

export interface UpdateWorkspaceFolderInput {
  readonly id: string;
  readonly name: string;
}

export interface UpdateWorkspaceInput {
  readonly activity?: string | null;
  readonly avatar?: string | null;
  readonly id: string;
  readonly name?: string | null;
  readonly settings?: WorkspaceSettingsInput | null;
}

export interface UserAvatarPreviewInput {
  readonly resolution: string;
  readonly url: string;
}

export interface UserSettingsInput {
  readonly darkMode?: boolean | null;
  readonly dateFormat?: string | null;
  readonly firstDayOfWeek?: number | null;
  readonly language?: string | null;
  readonly showEventAttendeeWithinOrganizations?: boolean | null;
  readonly showTimeblockAssignedWithinOrganizations?: boolean | null;
  readonly showTimeblockCompletionConfirmation?: boolean | null;
  readonly timeFormat?: string | null;
  readonly timezone?: string | null;
}

export interface UserTrackDataInput {
  readonly promo?: string | null;
  readonly signUpPage?: string | null;
  readonly utmCampaign?: string | null;
  readonly utmMedium?: string | null;
  readonly utmSource?: string | null;
  readonly utmTerm?: string | null;
}

export interface VerifyEmailInput {
  readonly verificationCode: string;
}

export interface WatchWorkspaceDemoVideoInput {
  readonly case?: number | null;
  readonly workspace: string;
}

export interface WorkspaceSettingsInput {
  readonly colorTypes?: ReadonlyArray<ColorTypeInput> | null;
  readonly defaultScheduledEventColor?: string | null;
  readonly defaultTaskColor?: string | null;
  readonly inheritColorTypes?: boolean | null;
  readonly showTaskTimerFinalizationModal?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
