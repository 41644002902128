import { useAppStore } from '../stores';
import { IServerData, IAnalyticsData } from '../interfaces';

export const setServerData = (payload: Partial<IServerData>) =>
  useAppStore.setState((state) => ({
    serverData: {
      ...state.serverData,
      ...payload,
    },
  }));

export const setAnalyticsData = (payload: Partial<IAnalyticsData>) =>
  useAppStore.setState((state) => ({
    analyticsData: {
      ...state.analyticsData,
      ...payload,
    },
  }));

export const setCurrentSpace = (payload: string) =>
  useAppStore.setState({
    currentSpace: payload,
  });

export const setToolbarOpen = (payload: boolean) => useAppStore.setState({ isToolbarOpen: payload });

export const setNavigationOpen = (payload: boolean) => useAppStore.setState({ isNavigationOpen: payload });
