import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import config from 'shared/config';

import { IAppStore } from '../interfaces';

// extra parenthesis https://docs.pmnd.rs/zustand/guides/typescript
export const useAppStore = create<IAppStore>()(
  devtools<IAppStore>(() => ({
    serverData: {
      online: true,
      status: 200,
    },
    analyticsData: {
      clientId: '',
      authMethod: '',
      gaSessionId: '',
      gaSessionNumber: '',
    },
    currentSpace: '',
    isToolbarOpen: localStorage.getItem(config.constants.toolsExpanded) !== 'false',
    isNavigationOpen: localStorage.getItem(config.constants.navigationExpanded) !== 'false',
  }))
);
