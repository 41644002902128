import { isNumber } from 'lodash';
import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

import { DIALOG_PADDING_VALUE } from '../model';

interface IDialogSeparatorProps {
  $verticalMargin?: number | string;
}

export const DialogSeparator = styled.hr<IDialogSeparatorProps>`
  margin: ${({ $verticalMargin = 30 }) => (isNumber($verticalMargin) ? `${$verticalMargin}px` : $verticalMargin)} -${DIALOG_PADDING_VALUE}px;
  border: none;
  border-bottom: 1px solid ${getGray400Color};
`;
