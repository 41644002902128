import * as React from 'react';
function SvgPlaySm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.978 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.882 8.282h0c.564.326.713.672.713.898 0 .226-.149.572-.713.898h0l-1.088.63-1.087.63s0 0 0 0c-.564.326-.937.281-1.132.169-.194-.113-.42-.415-.42-1.067V7.92c0-.652.226-.954.42-1.067.195-.112.568-.157 1.132.169 0 0 0 0 0 0l1.087.63 1.088.63z"
        fill="#8C939F"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgPlaySm;
