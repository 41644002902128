import { DateTime } from 'luxon';
import ObjectID from 'bson-objectid';
import { Logger } from 'core/logger/logger';
import config from 'shared/config';

import { apolloClient } from '../../apollo';

import { ITransaction, ICreateTransactionOptions } from './interfaces';

const logger = Logger.subject('TRANSACTION_MANAGER');

export class TransactionManager {
  transactions: ITransaction[] = [];

  constructor() {
    this.run();
  }

  get transactionList() {
    return this.transactions;
  }

  private run() {
    setInterval(() => {
      this.cleanup();
    }, 1000);
  }

  private cleanup() {
    //If server events turned off, app works in offline mode
    if (!config.events.server) {
      return;
    }

    this.transactions = this.transactions.reduce((result, current) => {
      const { id, expiresAt } = current;

      if (expiresAt === null || expiresAt > DateTime.now()) {
        result.push(current);

        return result;
      }

      // Roll-back transaction
      apolloClient.cache.removeOptimistic(id);

      logger.info('Roll-back expired transaction: ', id);

      return result;
    }, [] as ITransaction[]);
  }

  public create(options?: ICreateTransactionOptions) {
    const { timeout = 10000, releaseThreshold = 1 } = options || {};

    const transaction: ITransaction = {
      id: new ObjectID().toHexString(),
      expiresAt: timeout === 0 ? null : DateTime.now().plus({ millisecond: timeout }),
      releaseThreshold,
    };

    this.transactions.push(transaction);

    logger.info('Created a new transaction', transaction.id);

    return transaction.id;
  }

  public release(id: string, forced?: boolean) {
    let release = true;

    this.transactions = this.transactions.reduce((acc, current) => {
      if (current.id === id) {
        if (!forced && current.releaseThreshold > 1) {
          release = false;

          current.releaseThreshold -= 1;
        } else {
          return acc;
        }
      }

      acc.push(current);

      return acc;
    }, [] as ITransaction[]);

    if (release) {
      apolloClient.cache.removeOptimistic(id);

      logger.info('Released the transaction', id);
    }
  }

  public removeFromQueue(id: string) {
    this.transactions = this.transactions.filter((transaction) => transaction.id !== id);

    logger.info('Removed the transaction from the queue', id);
  }
}
