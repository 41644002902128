import styled, { css } from 'styled-components';
import {
  getGray100Color,
  getGray400Color,
  getLGBorderRadius,
  getQuinaryShadow,
  getSMFontSize,
  getWhiteColor,
} from 'core/theme/helpers';

interface IContainerProps {
  $fullWidth?: boolean;
}

interface IStyledLabelProps {
  $isDisabled?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  gap: 3px;
  width: max-content;
  padding: 3px;
  background: ${getGray100Color};
  border-radius: ${getLGBorderRadius};

  font-size: ${getSMFontSize};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;

      & > * {
        flex: 1 1;
      }
    `}
`;

export const Label = styled.label<IStyledLabelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  transition: all 300ms ease-out;
  border-radius: ${getLGBorderRadius};

  &:not(.active):hover {
    background: ${getGray400Color};
  }

  &.active {
    background: ${getWhiteColor};
    box-shadow: 0px 1px 2px 0px ${getQuinaryShadow};
  }

  ${({ theme, $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${theme.colors.gray900};
      cursor: default;
      pointer-events: none;
    `}
`;

export const HiddenInput = styled.input`
  display: none;
`;
