import React, { useRef, useMemo, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { isNumber, isNull, findLastIndex } from 'lodash';
import { filter } from 'rxjs/operators';
import { Logger } from 'core/logger/logger';

import { EventSubscriptionContext } from './context';
import { observable } from './observable';
import { IEvent, ISubscription, ISubscriptionData } from './interfaces';

interface IProps {
  children?: React.ReactNode;
}

export const EventSubscriptionProvider = (props: IProps) => {
  const { children } = props;

  const client = useApolloClient();

  const subscriptionsRef = useRef<ISubscription<any>[]>([]);

  const createSubscription = useCallback(<T,>(data: ISubscriptionData<T>) => {
    const { name, context, eventType, handler } = data;

    const subscription = observable
      .pipe(
        filter((event: IEvent<T>) => {
          const currentEventType = event.__metadata.type;

          if (Array.isArray(eventType)) {
            return eventType.some((type) => type === currentEventType);
          }

          return eventType === currentEventType;
        })
      )
      .subscribe({
        next: (event: IEvent<T>) => {
          handler(event, { client, ...context });

          const message = `${name ? `<${name}>` : ''} Handled event:`.trimStart();

          Logger.subject('EVENT_MANAGER').info(message, event.__metadata.type, event);
        },
      });

    return subscription;
  }, []);

  const subscribe = useCallback(<T,>(id: string, data: ISubscriptionData<T>) => {
    const subscriptions = subscriptionsRef.current;

    const lastIndex = subscriptions.length ? subscriptions.length - 1 : null;
    const lastPriority = isNumber(lastIndex) ? subscriptions[lastIndex].data.priority : null;

    if (isNull(lastPriority) || data.priority >= lastPriority) {
      const subscriptionIndex = findLastIndex(subscriptions, (subscription) => subscription.id === id);

      if (subscriptionIndex !== -1) {
        const currentSubscription = subscriptions[subscriptionIndex];

        currentSubscription.subscription.unsubscribe();

        subscriptions.splice(subscriptionIndex, 1);
      }

      subscriptions.push({
        id,
        data,
        subscription: createSubscription(data),
      });

      return;
    }

    const lastSubscriptionWithSamePriorityIndex = findLastIndex(
      subscriptions,
      (subscription) => subscription.data.priority === data.priority
    );

    const nextIndex = lastSubscriptionWithSamePriorityIndex + 1;

    const newSubscriptions = subscriptions.slice(0, nextIndex);

    newSubscriptions.push({
      id,
      data,
      subscription: createSubscription<T>(data),
    });

    for (let i = nextIndex; i < subscriptions.length; i++) {
      const currentItem = subscriptions[i];

      currentItem.subscription.unsubscribe();

      newSubscriptions.push({
        id: currentItem.id,
        data: currentItem.data,
        subscription: createSubscription<T>(currentItem.data),
      });
    }

    subscriptionsRef.current = newSubscriptions;
  }, []);

  const unsubscribe = useCallback((id: string) => {
    const subscriptions = subscriptionsRef.current;
    const subscriptionIndex = findLastIndex(subscriptions, (subscription) => subscription.id === id);

    if (subscriptionIndex === -1) {
      return;
    }

    const currentSubscription = subscriptions[subscriptionIndex];

    currentSubscription.subscription.unsubscribe();

    subscriptionsRef.current = subscriptions.filter((subscription) => subscription.id !== id);
  }, []);

  const values = useMemo(
    () => ({
      subscribe,
      unsubscribe,
    }),
    [subscribe, unsubscribe]
  );

  return <EventSubscriptionContext.Provider value={values}>{children}</EventSubscriptionContext.Provider>;
};
