export enum IndicatorStatus {
  Error,
  Success,
  Default,
}

export interface IStatusIndicatorProps {
  status?: IndicatorStatus;
  children?: React.ReactNode;
}
