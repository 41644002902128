import React, { useMemo } from 'react';
import { useSearch } from 'hooks/search';
import { Dropdown, DropdownItem } from 'shared/components';

import { FUSE_OPTIONS, ValueType, IBasicSelectorProps } from '../model';

import { BasicSelectorTrigger } from './basic-selector-trigger';

export const BasicSelector = <T extends ValueType>(props: IBasicSelectorProps<T>) => {
  const {
    value,
    options,
    trigger = BasicSelectorTrigger,
    searchable = false,
    searchValue,
    triggerProps,
    dropdownItem = DropdownItem,
    onSelect,
    onSearch,
    ...otherProps
  } = props;
  // @ts-ignore
  const { result, searchValue: innerSearchValue, search, resetSearch } = useSearch(options, FUSE_OPTIONS);

  const selectedOption = useMemo(() => {
    const item = options.find((option) => option.value === value);

    return item;
  }, [value, options]);

  const preparedSearchValue = searchValue ?? innerSearchValue;

  const DropdownComponent = dropdownItem;

  const _handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;

    search(newSearchValue);
  };

  const _handleSelect = (selectedValue: T, close: () => void) => () => {
    if (selectedValue !== value) {
      onSelect?.(selectedValue);
    }

    close();
    resetSearch();
  };

  const searchCallback = searchable ? onSearch ?? _handleSearch : undefined;

  return (
    <Dropdown
      trigger={trigger}
      triggerProps={{
        ...triggerProps,
        selectedOption,
      }}
      searchValue={preparedSearchValue}
      onSearch={searchCallback}
      {...otherProps}
    >
      {(close) => (
        <>
          {result.map((option) => (
            <DropdownComponent
              key={option.value}
              icon={option.icon}
              label={option.label}
              value={option.value}
              active={option.value === value}
              onClick={_handleSelect(option.value as any, close)}
            />
          ))}
        </>
      )}
    </Dropdown>
  );
};
