import { find, isUndefined, reduce } from 'lodash';
import { FieldMergeFunction, InMemoryCache, Reference } from '@apollo/client';
import { TaskStatus } from 'core/graphql/interfaces/graphql.interfaces';

const defaultMergeFunction: FieldMergeFunction = (existing = [] as any[], incoming: any[]) => {
  return reduce(
    incoming,
    (acc, current) => {
      if (current && find(acc, { __ref: current.__ref }) === undefined) {
        acc.push(current);
      }

      return acc;
    },
    [...existing]
  );
};

const tasksMergeFunction =
  (field: 'commonRank' | 'statusRank'): FieldMergeFunction =>
  (existing = [] as any[], incoming: any[], { readField }) => {
    if (existing.length === 0) {
      return incoming;
    }

    const result = [];
    const incomingRefs = new Set();

    let maxCompletedRank = 0;

    for (let i = 0; i < incoming.length; i++) {
      const incomingItem = incoming[i];

      const isItemIncluded = incomingRefs.has(incomingItem.__ref);

      if (isItemIncluded) {
        continue;
      }

      result.push(incomingItem);
      incomingRefs.add(incomingItem.__ref);

      const rank = readField<number>(field, incomingItem);
      const statusRef = readField<Reference>('statusV2', incomingItem);
      const statusKind = readField<string>('kind', statusRef);

      const isActive = statusKind !== TaskStatus.Completed;

      if (isActive || isUndefined(rank)) {
        continue;
      }

      maxCompletedRank = rank > maxCompletedRank ? rank : maxCompletedRank;
    }

    for (let i = 0; i < existing.length; i++) {
      const existingItem = existing[i];

      const isItemIncluded = incomingRefs.has(existingItem.__ref);

      if (isItemIncluded) {
        continue;
      }

      const existingRank = readField<number>(field, existingItem);
      const existingStatusRef = readField<Reference>('statusV2', existingItem);
      const existingStatusKind = readField<string>('kind', existingStatusRef);

      const isActive = existingStatusKind !== TaskStatus.Completed;

      if (isActive || isUndefined(existingRank)) {
        continue;
      }

      if (existingRank > maxCompletedRank) {
        continue;
      }

      result.push(existingItem);
    }

    return result;
  };

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        tags: {
          merge: false,
          keyArgs: ['__typename', 'workspaceId'],
        },
        team: {
          read(existing, { variables, toReference }) {
            const id = variables?.id;

            if (existing || !id) {
              return existing;
            }

            return toReference({ __typename: 'TeamType', id });
          },
        },
        tasks: {
          keyArgs: ['input', ['refType', 'refId', 'color', 'status', 'sortBy', 'assignee']],
        },
        notes: {
          merge: false,
        },
        project: {
          read(existing, { variables, toReference }) {
            const id = variables?.id;

            if (existing || !id) {
              return existing;
            }

            return toReference({ __typename: 'ProjectType', id });
          },
        },
        workspace: {
          keyArgs: ['id'],
          read(existing, { variables, toReference }) {
            const id = variables?.id;

            if (existing || !id) {
              return existing;
            }

            return toReference({ __typename: 'WorkspaceType', id });
          },
        },
        board: {
          keyArgs: ['input', ['refType', 'refId']],
        },
        kanban: {
          keyArgs: ['input', ['refType', 'refId']],
        },
        tableView: {
          keyArgs: ['input', ['refType', 'refId']],
        },
        taskTimers: {
          merge: false,
        },
        getChatMessages: {
          keyArgs: ['input', ['refType', 'refId']],
        },
        getAppNotifications: {
          keyArgs: ['input', ['filter']],
        },
      },
    },
    // USER
    UserAvatarPreviewType: {
      keyFields: false,
    },
    UserLastReferenceType: {
      keyFields: false,
    },
    GoogleCalendarAppConnectionParamsType: {
      keyFields: false,
    },
    // NOTE
    NoteAclType: {
      keyFields: false,
    },
    // TEAM
    TeamType: {
      fields: {
        participants: {
          merge: false,
        },
      },
    },
    TeamAclType: {
      keyFields: false,
    },
    TeamSettingsType: {
      keyFields: false,
    },
    // PROJECT
    ProjectType: {
      fields: {
        participants: {
          merge: false,
        },
      },
    },
    ProjectAclType: {
      keyFields: false,
    },
    ProjectSettingsType: {
      keyFields: false,
    },
    // WORKSPACE
    WorkspaceType: {
      fields: {
        participants: {
          merge: false,
        },
        teamFoldersSchema: {
          merge: false,
        },
        projectFoldersSchema: {
          merge: false,
        },
      },
    },
    WorkspaceAclType: {
      keyFields: false,
    },
    WorkspaceBillingType: {
      keyFields: false,
    },
    WorkspaceSettingsType: {
      keyFields: false,
    },
    WorkspaceBillingSubscriptionType: {
      keyFields: false,
    },
    // SCHEDULED EVENT
    ScheduledEventType: {
      fields: {
        participants: {
          merge: false,
        },
        attachments: {
          merge: defaultMergeFunction,
        },
      },
    },
    ScheduledEventAclType: {
      keyFields: false,
    },
    ScheduledEventRecurrenceRuleType: {
      keyFields: false,
    },
    // TASK
    TaskType: {
      fields: {
        subtasks: {
          merge: false,
        },
        timeblocks: {
          merge: false,
        },
        attachments: {
          merge: defaultMergeFunction,
        },
      },
    },
    TaskAclType: {
      keyFields: false,
    },
    TaskRecurrenceRuleType: {
      keyFields: false,
    },
    // CHAT
    ChatMessageType: {
      fields: {
        attachments: {
          merge: false,
        },
      },
    },
    // ATTACHMENT
    AttachmentMetaType: {
      keyFields: false,
    },
    AttachmentPreviewType: {
      keyFields: false,
    },
    // COLOR TYPE
    ColorTypeType: {
      keyFields: false,
    },
    RecentlyInvitedType: {
      keyFields: false,
    },
    BoardType: {
      keyFields: false,
      fields: {
        tasks: {
          merge: false,
        },
        scheduledEvents: {
          merge: false,
        },
      },
    },
    KanbanType: {
      keyFields: false,
      fields: {
        tasks: {
          merge: tasksMergeFunction('statusRank'),
        },
      },
    },
    TableViewType: {
      keyFields: false,
      fields: {
        tasks: {
          merge: tasksMergeFunction('commonRank'),
        },
      },
    },
    TaskBatchType: {
      keyFields: false,
      fields: {
        tasks: {
          merge: defaultMergeFunction,
        },
      },
    },
    ChatMessageBatchType: {
      keyFields: false,
      fields: {
        chatMessages: {
          merge: defaultMergeFunction,
        },
      },
    },
    AppNotificationBatchType: {
      keyFields: false,
      fields: {
        appNotifications: {
          merge: defaultMergeFunction,
        },
      },
    },
  },
});
