import { Subscription } from 'rxjs';

export interface IEvent<T = any> {
  value: T;
  __metadata: IEventMetadata;
}

export interface IEventMetadata {
  type: EventType;
  transaction?: string | null;
  subscription?: boolean;
  optimisticTransaction?: string;
}

export type EventContext = Record<string, unknown>;

export type HandlerType<T> = (event: IEvent<T>, context: any) => void;

export interface ISubscriptionData<T> {
  name?: string;
  context?: EventContext;
  priority: number;
  eventType: EventType | EventType[];
  handler: HandlerType<T>;
}

export interface ISubscription<T> {
  id: string;
  data: ISubscriptionData<T>;
  subscription: Subscription;
}

export interface IEventSubscriptionContext {
  subscribe: <T>(id: string, data: ISubscriptionData<T>) => void;
  unsubscribe: (id: string) => void;
}

export enum EventType {
  SYNC = 'SyncEvent',

  CLIENT_UPDATE = 'ClientUpdateEvent',
  BROADCAST_MESSAGE = 'BroadcastMessageEvent',

  TAG_CREATED = 'TagCreatedEvent',
  TAG_UPDATED = 'TagUpdatedEvent',
  TAG_DELETED = 'TagDeletedEvent',

  USER_UPDATED = 'UserUpdatedEvent',

  NOTE_CREATED = 'NoteCreatedEvent',
  NOTE_UPDATED = 'NoteUpdatedEvent',
  NOTE_DELETED = 'NoteDeletedEvent',

  TEAM_CREATED = 'TeamCreatedEvent',
  TEAM_UPDATED = 'TeamUpdatedEvent',
  TEAM_DELETED = 'TeamDeletedEvent',

  TASK_CREATED = 'TaskCreatedEvent',
  TASK_UPDATED = 'TaskUpdatedEvent',
  TASK_DELETED = 'TaskDeletedEvent',

  TASK_TIMER_CREATED = 'TaskTimerCreatedEvent',
  TASK_TIMER_UPDATED = 'TaskTimerUpdatedEvent',
  TASK_TIMER_DELETED = 'TaskTimerDeletedEvent',

  PROJECT_CREATED = 'ProjectCreatedEvent',
  PROJECT_UPDATED = 'ProjectUpdatedEvent',
  PROJECT_DELETED = 'ProjectDeletedEvent',

  WORKSPACE_CREATED = 'WorkspaceCreatedEvent',
  WORKSPACE_UPDATED = 'WorkspaceUpdatedEvent',
  WORKSPACE_DELETED = 'WorkspaceDeletedEvent',

  CHAT_MESSAGE_CREATED = 'ChatMessageCreatedEvent',
  CHAT_MESSAGE_UPDATED = 'ChatMessageUpdatedEvent',
  CHAT_MESSAGE_DELETED = 'ChatMessageDeletedEvent',

  WORKSPACE_FOLDER_CREATED = 'WorkspaceFolderCreatedEvent',
  WORKSPACE_FOLDER_UPDATED = 'WorkspaceFolderUpdatedEvent',
  WORKSPACE_FOLDER_DELETED = 'WorkspaceFolderDeletedEvent',
  WORKSPACE_FOLDER_CHILD_CREATED = 'WorkspaceFolderChildCreatedEvent',
  WORKSPACE_FOLDER_CHILD_UPDATED = 'WorkspaceFolderChildUpdatedEvent',
  WORKSPACE_FOLDER_CHILD_DELETED = 'WorkspaceFolderChildDeletedEvent',

  SYNC_WORKSPACE_FOLDERS_SCHEMA = 'SyncWorkspaceFoldersSchemaEvent',

  ATTACHMENT_CREATED = 'AttachmentCreatedEvent',
  ATTACHMENT_UPDATED = 'AttachmentUpdatedEvent',
  ATTACHMENT_DELETED = 'AttachmentDeletedEvent',

  USER_INVITE_CREATED = 'UserInviteCreatedEvent',
  USER_INVITE_DELETED = 'UserInviteDeletedEvent',

  APP_INTEGRATION_CREATED = 'AppConnectionCreatedEvent',
  APP_INTEGRATION_UPDATED = 'AppConnectionUpdatedEvent',
  APP_INTEGRATION_DELETED = 'AppConnectionDeletedEvent',

  APP_NOTIFICATION_CREATED = 'AppNotificationCreatedEvent',
  APP_NOTIFICATION_UPDATED = 'AppNotificationUpdatedEvent',

  SCHEDULED_EVENT_CREATED = 'ScheduledEventCreatedEvent',
  SCHEDULED_EVENT_UPDATED = 'ScheduledEventUpdatedEvent',
  SCHEDULED_EVENT_DELETED = 'ScheduledEventDeletedEvent',

  EXTERNAL_LINKED_ACCOUNT_CREATED = 'ExternalLinkedAccountCreatedEvent',
  EXTERNAL_LINKED_ACCOUNT_UPDATED = 'ExternalLinkedAccountUpdatedEvent',
  EXTERNAL_LINKED_ACCOUNT_DELETED = 'ExternalLinkedAccountDeletedEvent',

  USER_APP_NOTIFICATION_META_UPDATED = 'UserAppNotificationMetaUpdated',
}
