import React from 'react';

import { IBasicSelectorTriggerProps, ValueType } from '../../model';

import { StyledButtonTrigger } from './styled';

export const BasicSelectorTrigger = <T extends ValueType>(props: IBasicSelectorTriggerProps<T>) => {
  const { icon, label, placeholder = 'None', className, selectedOption, ...otherProps } = props;

  const isSelected = !!selectedOption;
  const showingIcon = selectedOption?.icon ?? icon;

  const _getText = () => {
    if (isSelected) {
      return label ?? selectedOption.displayedLabel ?? selectedOption.label;
    }

    return placeholder;
  };

  const _getClasses = () => {
    return `${className} ${isSelected ? 'selected' : 'placeholder'}`.trim();
  };

  return <StyledButtonTrigger {...otherProps} icon={showingIcon} label={_getText()} className={_getClasses()} />;
};
