import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { Logger } from 'core/logger/logger';
import config from 'shared/config';

import { apolloCache } from './apollo-cache';
import { subscriptionClient } from './subscription-client';
import { checkServerStatus } from './check-server-status';

const clientLogger = Logger.subject('GQL_CLIENT');

// Setup ws link
const wsLink = new GraphQLWsLink(subscriptionClient);

// Setup HTTP link
const httpLink = new HttpLink({
  uri: config.graphql.url,
});

// Setup error link
const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      clientLogger.error(`GraphQL error: ${error.message}`, error);

      if (error.message === 'Unauthorized') {
        localStorage.removeItem(config.constants.isVerified);
        localStorage.removeItem(config.constants.authTokenStorageKey);

        window.location.reload();
      }
    });
  }

  if (networkError) {
    clientLogger.error(`Network error: ${networkError.message}`, networkError);

    checkServerStatus();
  }
});

// Setup headers provided together with each request (e.g. authorization token)
const contextLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(config.constants.authTokenStorageKey);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    headers: {
      ...headers,
      tz: timeZone,
      authorization: token ? `Bearer ${token}` : '',
      'x-bordio-platform': 'web',
      'x-bordio-build-version': config.appVersion,
    },
  };
});

// Using ability to split, we can send data to each link depending on on what kind of operation is being sent.
const transportLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  name: 'web',
  link: ApolloLink.from([errorLink, contextLink, transportLink]),
  cache: apolloCache,
  version: config.appVersion,
});
