import * as React from 'react';
function SvgTimerSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.563 9.938A6.565 6.565 0 019 16.5a6.565 6.565 0 01-6.563-6.563A6.565 6.565 0 019 3.375a6.565 6.565 0 016.563 6.563zM9 6v3.75"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 1.5h4.5"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgTimerSm;
