import * as React from 'react';
function SvgPlayXl(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.968 23.833c5.983 0 10.833-4.85 10.833-10.834 0-5.983-4.85-10.833-10.833-10.833-5.983 0-10.833 4.85-10.833 10.833 0 5.983 4.85 10.834 10.833 10.834z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.852 11.732h0c.865.5 1.163 1.075 1.163 1.529 0 .454-.298 1.029-1.162 1.528h0l-1.572.91-1.57.91s0 0 0 0c-.864.499-1.51.47-1.902.243-.392-.226-.74-.773-.74-1.771V11.44c0-.998.348-1.544.74-1.771.392-.227 1.038-.256 1.902.244 0 0 0 0 0 0l1.57.91 1.571.91z"
        fill="#8C939F"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgPlayXl;
