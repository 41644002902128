import React from 'react';
import { AngleDownSm } from 'shared/components/icons';

import { TriggerButton, TriggerText } from './styled';
import { IButtonTriggerProps } from './interfaces';

export const ButtonTrigger = (props: IButtonTriggerProps) => {
  const {
    icon,
    label,
    style,
    isOpen = false,
    variant = 'ghost',
    disabled,
    children,
    iconColor = 'inherit',
    withArrow = true,
    className,
    borderRadius = 'md',
    getRef,
    onToggle,
  } = props;

  const _getClasses = () => {
    let classes = `${className} ${variant}`;

    if (isOpen) {
      classes = `${classes} active`;
    }

    return classes.trim();
  };

  return (
    <TriggerButton
      ref={getRef}
      type="button"
      style={style}
      variant={variant}
      disabled={disabled}
      leftIcon={icon}
      rightIcon={withArrow ? <AngleDownSm width={16} height={16} className="arrow-icon" /> : null}
      iconColor={iconColor}
      className={_getClasses()}
      borderRadius={borderRadius}
      $variant={variant}
      $withArrow={withArrow}
      onClick={onToggle}
    >
      {label && <TriggerText className="trigger-text">{label}</TriggerText>}

      {children}
    </TriggerButton>
  );
};
