import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getGray800Color, getMDBorderRadius, getSMFontSize } from 'core/theme/helpers';

import { IStyledContainerProps, IStyledInputProps, IStyledIconWrapperProps } from './interfaces';

const defaultContainerStyles = css`
  display: flex;
  position: relative;
  box-sizing: border-box;
  transition: all 300ms ease-out;
  min-height: 40px;
  border-radius: ${getMDBorderRadius};
`;

const getContainerVariantStyles = (props: ThemedStyledProps<IStyledContainerProps, ITheme>) => {
  const { theme, $error, $variant } = props;

  switch ($variant) {
    case 'ghost':
      return css`
        min-height: auto;
        background: none;
        border-radius: 0;
      `;
    case 'bordered': {
      return css`
        border: 1px solid ${$error ? theme.colors.red400 : theme.colors.gray500};
        background: ${theme.colors.white};

        &:hover {
          border-color: ${theme.colors.gray600};
        }

        &:focus-within {
          border-color: ${theme.colors.blue250};
        }
      `;
    }
    case 'highlighted':
      return css`
        &:hover,
        &:focus-within {
          background: ${theme.colors.gray100};
        }
      `;
    default:
      return css`
        background: ${theme.colors.gray100};
      `;
  }
};

const getInputVariantStyles = (props: ThemedStyledProps<IStyledInputProps, ITheme>) => {
  const { theme, $variant } = props;

  switch ($variant) {
    case 'ghost':
      return css`
        color: ${theme.colors.black};
        padding: 0;
      `;
    case 'bordered':
      return css`
        color: ${theme.colors.black};
        padding: 9px 15px;

        &:disabled {
          color: ${theme.colors.gray800};
        }
      `;
    default:
      return css`
        color: ${theme.colors.black};
        padding: 9px 15px;
      `;
  }
};

const getInputIconStyles = (props: ThemedStyledProps<IStyledInputProps, ITheme>) => {
  const { $withIcon, $iconPosition } = props;

  if (!$withIcon) {
    return;
  }

  switch ($iconPosition) {
    case 'right':
      return css`
        padding-right: 21px;
      `;
    default:
      return css`
        padding-left: 39px;
      `;
  }
};

const getIconPositionStyles = (props: ThemedStyledProps<IStyledIconWrapperProps, ITheme>) => {
  const { $iconPosition } = props;

  switch ($iconPosition) {
    case 'right':
      return css`
        right: 15px;
      `;
    default:
      return css`
        left: 15px;
      `;
  }
};

export const Container = styled.div<IStyledContainerProps>`
  ${defaultContainerStyles}
  ${getContainerVariantStyles}
`;

export const StyledInput = styled.input<IStyledInputProps>`
  ${getInputVariantStyles}
  ${getInputIconStyles};

  font-size: ${getSMFontSize};

  flex: 1 1;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  background: none;
  box-sizing: border-box;
  transition: inherit;
  border-radius: inherit;

  &::placeholder {
    color: ${getGray800Color};
    transition: inherit;
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  ${({ theme, $error }) =>
    $error &&
    css`
      color: ${theme.colors.red400};

      &::placeholder {
        color: ${theme.colors.red400};
      }
    `}
`;

export const IconContainer = styled.i<IStyledIconWrapperProps>`
  ${getIconPositionStyles};

  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-style: normal;
`;
