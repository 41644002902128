import { ThemedStyledProps } from 'styled-components';

import { ITheme } from '../theme.interface';

export const getPrimaryGreenColor = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.primary_green;

export const getBlackColor = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.black;

export const getWhiteColor = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.white;

export const getRed100Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.red100;

export const getRef200Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.red200;

export const getRed300Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.red300;

export const getRed400Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.red400;

export const getBlue100Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue100;

export const getBlue200Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue200;

export const getBlue250Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue250;

export const getBlue280Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue280;

export const getBlue300Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue300;

export const getBlue400Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue400;

export const getBlue500Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue500;

export const getBlue600Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue600;

export const getBlue700Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.blue700;

export const getGray100Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray100;

export const getGray200Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray200;

export const getGray300Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray300;

export const getGray400Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray400;

export const getGray500Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray500;

export const getGray600Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray600;

export const getGray700Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray700;

export const getGray800Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray800;

export const getGray900Color = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.colors.gray900;
