import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme';
import { Button } from 'shared/components/button';

import { IStyledButtonTriggerProps } from './interfaces';

const getTriggerButtonStyles = (props: ThemedStyledProps<IStyledButtonTriggerProps, ITheme>) => {
  const { $variant, $withArrow } = props;

  let sidePadding = 10;

  switch ($variant) {
    case 'ghost':
    case 'ghost-danger':
    case 'ghost-primary':
    case 'ghost-secondary':
      return;
    case 'bordered': {
      sidePadding = 15;

      break;
    }
  }

  return css`
    padding: ${() => ($withArrow ? `7px 10px 7px ${sidePadding}px` : `7px ${sidePadding}px`)};
  `;
};

const getTriggerButtonVariantStyles = (props: ThemedStyledProps<IStyledButtonTriggerProps, ITheme>) => {
  const { theme, $variant } = props;

  switch ($variant) {
    case 'primary':
      return css`
        &.active {
          background: ${theme.colors.blue400};
        }
      `;
    case 'filled':
      return css`
        &.active {
          background: ${theme.colors.gray400};
        }
      `;
    case 'filled-secondary':
      return css`
        &.active {
          background: ${theme.colors.blue500};
        }
      `;
    case 'filled-selected':
      return css`
        &.active {
          background: ${theme.colors.blue200};
        }
      `;
    case 'filled-quinary': {
      return css`
        &.active {
          color: ${theme.colors.black};
          background: ${theme.colors.blue200};

          & .left-icon-container {
            color: ${theme.colors.blue300};
          }
        }
      `;
    }
    case 'bordered':
      return css`
        &.active {
          border-color: ${theme.colors.blue250};
        }
      `;
    case 'highlighted':
      return css`
        &.active {
          background: ${theme.colors.gray200};
        }
      `;
    case 'ghost':
    case 'ghost-secondary':
      return css`
        &.active {
          color: ${theme.colors.blue300};
        }
      `;
    case 'ghost-primary':
      return css`
        &.active {
          color: ${theme.colors.blue400};
        }
      `;
  }
};

export const TriggerButton = styled(Button)<IStyledButtonTriggerProps>`
  ${getTriggerButtonStyles}
  ${getTriggerButtonVariantStyles}

  overflow: hidden;
  user-select: none;

  &.active {
    & .right-icon-container {
      transform: rotate(-180deg);
    }
  }

  & .right-icon-container {
    transition: inherit;
    margin-left: auto;
  }
`;

export const TriggerText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
