import * as React from 'react';
function SvgPauseXl(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.968 23.833c5.983 0 10.833-4.85 10.833-10.834 0-5.983-4.85-10.833-10.833-10.833-5.983 0-10.833 4.85-10.833 10.833 0 5.983 4.85 10.834 10.833 10.834z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.316 9.573v6.852a.791.791 0 01-.034.281.68.68 0 01-.226.027H9.514a.659.659 0 01-.214-.025.797.797 0 01-.034-.283V9.573c0-.179.024-.257.034-.283a.659.659 0 01.214-.024h1.554c.128 0 .19.015.214.024.01.026.034.104.034.283zm-.021 7.128l-.003.001a.014.014 0 01.003-.002zm-2.008.001l.003.002a.015.015 0 01-.003-.002zm0-7.405l.003-.002a.012.012 0 01-.003.002zm2.008 0l-.003-.002c.002 0 .003.001.003.002zM16.734 9.573v6.852a.79.79 0 01-.034.283.661.661 0 01-.217.025h-1.548a.66.66 0 01-.217-.025.79.79 0 01-.034-.283V9.573a.79.79 0 01.034-.282.66.66 0 01.217-.025h1.548a.66.66 0 01.217.025.79.79 0 01.034.282zm-.021 7.129l-.003.001.003-.001zm-2.008 0l.003.001-.003-.001zm0-7.405l.003-.002a.012.012 0 01-.003.002zm2.008 0s-.002 0-.003-.002l.003.002z"
        fill="#8C939F"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgPauseXl;
